<template>
  <v-dialog v-model="data.dialog" persistent max-width="600px">
    <v-card v-if="loading" :color="isDelete ? 'error' : 'primary'" dark>
      <v-card-text>
        <span class="font-weight-bold text-white">{{ $t('loading') }}</span>
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-if="!loading">
      <v-card-title
        :class="{'form-danger': isDelete, 'form-info': isUpdate, 'form-primary': isCreate}"
      >
        <span v-if="isCreate" class="">{{ $('create') }}</span>
        <span v-if="isUpdate" class="">{{ $('update') }}</span>
        <span v-if="isDelete" class="">{{ $('delete') }}</span>
      </v-card-title>
      <v-card-text>
        <h2 v-if="isDelete && item" class="my-3">
          {{ $('delete_msg', [item.name]) }}
        </h2>
        <v-form
          v-if="!isDelete"
          ref="form"
          v-model="valid"
        >
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  v-model="form.name"
                  v-max-length="200"
                  :label="$('name')"
                  :rules="rules.name"
                  autocomplete="new-password"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  v-model="form.phone"
                  v-max-length="11"
                  :label="$('phone')"
                  :rules="rules.phone"
                  autocomplete="new-password"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  v-model="form.password"
                  v-max-length="200"
                  :label="$('password')"
                  :rules="isCreate ? rules.password : []"
                  autocomplete="new-password"
                  type="password"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-checkbox
                  v-model="form.permission_post"
                  :label="$('permission_post')"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-checkbox
                  v-model="form.permission_attendance"
                  :label="$('permission_attendance')"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-checkbox
                  v-model="form.permission_exam"
                  :label="$('permission_exam')"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-select
                  v-model="form.subjects"
                  :items="subjects"
                  multiple
                  return-object
                  chips
                  deletable-chips
                  item-value="id"
                  :label="$('subjects')"
                >
                  <template #selection="{item}">
                    <v-chip
                      small
                      color="primary"
                    >
                      {{ item.stage.name }} - {{ item.name }}
                    </v-chip>
                  </template>
                  <template #item="{item}">
                    {{ item.stage.name }} - {{ item.name }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="light"
          outlined
          @click="dialogModal(false)"
        >
          {{ $t('form.cancel') }}
        </v-btn>
        <v-btn
          v-if="isCreate"
          color="primary"
          :disabled="!valid || submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.create') }}
        </v-btn>
        <v-btn
          v-if="isUpdate"
          color="info"
          :disabled="!valid || submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.save') }}
        </v-btn>
        <v-btn
          v-if="isDelete"
          color="error"
          :disabled="submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '@axios'
import toast from '@/store/toast'

export default {
  name: 'TeacherDialog',
  props: {
    data: {
      type: Object,
      default: () => ({
        dialog: false,
        id: null,
        type: 'create',
      }),
    },
    dialogModal: {
      type: Function,
      default: state => {
        this.data.dialog = state
      },
    },
    translationKey: {
      type: String,
      default: () => '',
    },
  },
  emits: ['update:table'],
  data: () => ({
    loading: true,
    submitLoading: false,
    valid: false,
    item: null,
    subjects: [],
    form: {
      name: '',
      phone: '',
      password: '',
      permission_post: false,
      permission_exam: false,
      permission_attendance: false,
      subjects: [],
    },
  }),
  computed: {
    isCreate() {
      return this.data.type === 'create'
    },
    isUpdate() {
      return this.data.type === 'update'
    },
    isDelete() {
      return this.data.type === 'delete'
    },
    rules() {
      const rules = {};
      rules.name = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.name`)]),
      ];
      rules.phone = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.phone`)]),
      ];
      rules.password = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.password`)]),
      ];

      return rules;
    },
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(val) {
        try {
          this.loading = true;
          if (val.id) {
            const teacher = await axios.get(`/teachers/${val.id}`);
            this.item = teacher.data.data;
            this.form = this.item;
          }
          if (!val.dialog) {
            this.resetForm();
          }
        } catch (error) {
          //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
        } finally {
          this.loading = false;
        }
      },
    },
  },
  async mounted() {
    const response = await axios.get('/subjects?with_stage')
    this.subjects = response.data.data
  },
  methods: {
    async submit() {
      try {
        this.submitLoading = true;
        if (this.isDelete) {
          await axios.delete(`/teachers/delete/${this.data.id}`);
          await toast.dispatch('success', this.$t('form.success.delete'))
          this.$emit('update:table')
          this.dialogModal(false)

          return;
        }
        if (this.$refs.form.validate() === false) {
          return;
        }
        if (this.isCreate) {
          await axios.post('/teachers/create', {
            name: this.form.name,
            phone: this.form.phone,
            password: this.form.password,
            permission_post: this.form.permission_post,
            permission_exam: this.form.permission_exam,
            permission_attendance: this.form.permission_attendance,
            subjects: this.form.subjects.map(s => s.id),
          });
          await toast.dispatch('success', this.$t('form.success.create'))
        } else if (this.isUpdate) {
          await axios.post(`/teachers/edit/${this.data.id}`, {
            name: this.form.name,
            phone: this.form.phone,
            password: this.form.password,
            permission_post: this.form.permission_post,
            permission_exam: this.form.permission_exam,
            permission_attendance: this.form.permission_attendance,
            subjects: this.form.subjects.map(s => s.id),
          });
          await toast.dispatch('success', this.$t('form.success.update'))
        }
        this.$emit('update:table')
        this.dialogModal(false)
      } catch (error) {
        //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
      } finally {
        this.submitLoading = false;
      }
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.form = {
        name: '',
        phone: '',
        password: '',
        permission_post: false,
        permission_exam: false,
        permission_attendance: false,
        subjects: [],
      };
      this.item = null;
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.modal.${key}`, parameters);
    },
  },
}
</script>

<style scoped>

</style>
